import React from 'react';
import PropTypes from 'prop-types';
import { useTheme,makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, colors,  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from 'components/molecules';
import { CardCategoryLink, CardBase } from 'components/organisms';
import { Icon, Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
   textWhite: {
    color: theme.palette.primary.contrastText,
  },
    avatar: {
 width: 110,
    height: 110,
    // border: `4px solid ${theme.palette.alternate.dark}`,
    // borderRadius: '100%',
    // boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  },
    listItemAvatar: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
}));

const Categories = props => {
  const { className, ...rest } = props;
    const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
const data = t('howItWorks.engineerSteps');
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={t('howItWorks.categoriesTitle')}
        label={t('howItWorks.categoriesLabel')}
        fadeUp
      />
    
      <Grid container spacing={isMd ? 4 : 2}>
        {data?.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={3}
            data-aos="fade-up"
          >
           <CardBase withShadow liftUp >
           <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar {...item.avatar} className={classes.avatar} />
                </ListItemAvatar>
            <ListItem disableGutters className={classes.listItem}>
                
                <ListItemText
                  className={classes.listItemText}
                  primary={item.title}
                  secondary={item.subtitle}
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: 'h6',
                    align: isMd ? 'left' : 'center',
                  }}
                  secondaryTypographyProps={{
                    color: 'textPrimary',
                    align: isMd ? 'left' : 'center',
                    variant: 'body1'
                  }}
                />
              </ListItem>
             {/* <Icon 
                fontIconClass={item.icon}
                size="medium"
            
                style={{marginBottom:'1rem', color:theme.palette.primary.main}}
              />
              <SectionHeader
                title={<span className={classes.textBlue}>{item.title}</span>}
                subtitle={<span className={classes.textBlue}>{item.subtitle}</span>}
                subtitleVariant="body1"
                titleVariant='h6'
              />*/}
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Categories.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Categories;
