import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, ListItem, ListItemText,colors, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { DescriptionListIcon, CardJobMinimal, CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  fontWeightBold: {
    fontWeight: 'bold',
  },
  faqTitle: {
    display: 'block',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  textBlue:{
    color: theme.palette.embarq.primary.dark,
  },
   cardBase: {
    // boxShadow: 'none',

    // background: theme.palette.embarq.neutral.light,
  },
  accord:{
    boxShadow:'none',
    //    sette.embarq.primary.dark,
  },
  accordSumm:{
    borderBottom:'solid 0.1rem',
    color: theme.palette.embarq.secondary.dark
    //    sette.embarq.primary.dark,
  },
  faqItem:{
    color: theme.palette.embarq.neutral.dark,
  },
}));

const Faq = props => {
  const {  className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const data = t('howItWorks.faqItems');

 return (
    <div className={className} {...rest}>
    <CardBase className={classes.cardBase} >
      <Grid container spacing={4}>
        <Grid item alignItems="flex-start" xs={12} md={4}>
         <SectionHeader
            style={{padding:'2rem'}}
              title={
                <span className={classes.textBlue}>
                  {t('howItWorks.faqTitle')}
                </span>
                }
                 data-aos="fade-up"
              subtitleVariant="h5"
              align="left"
              titleVariant="h3"
            />

        </Grid>
        <Grid  item md={8} >
          <Grid container   spacing={2}>
            {data?.map((item, index) => (
              <Grid item xs={12} key={index} data-aos="fade-up">
              <Accordion className={classes.accord}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  className={classes.accordSumm}
                  id="panel1a-header"
                >
                  <Typography variant='h5' className={classes.textBlue}>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.faqItem} variant='subtitle1'>
                   {item.response}
                  </Typography>
                </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
      </Grid>
       
        </Grid>
        </CardBase>
    </div>
  );
};

Faq.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Faq;
