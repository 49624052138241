import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { IconButton, List, ListItem, Typography } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';


import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 0),
    },
    background: theme.palette.embarq.neutral.dark,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  logoContainerItem: {
    paddingTop: 0,
  },
 logoContainer: {
    width: 100,
    height: 33,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  title:{
    color:theme.palette.text.light,
    textAlign:'end',
    display: 'block'
  },
  privacy:{
     color:theme.palette.text.light,
  }

}));

const Footer = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.footerContainer}>
        <List disablePadding>
          <ListItem disableGutters className={classes.logoContainerItem}>
            <div className={classes.logoContainer}>
              <a href="/" title="embarq">
                <Image
                  className={classes.logoImage}
                  src="/images/logos/Logo-light.svg"
                  alt="embarq"
                  lazy={false}
                />
              </a>
            </div>
          </ListItem>
          <ListItem disableGutters>
            <IconButton className={classes.socialIcon} href='https://www.linkedin.com/company/ac780/' target="_blank">
              <LinkedInIcon className={classes.icon} />
            </IconButton>
            <IconButton className={classes.socialIcon}>
                <TwitterIcon className={classes.icon} />
              </IconButton>
          </ListItem>
           <ListItem disableGutters  className={classes.title}>
              <Typography 
                variant='caption' 
                className={classes.privacy}
                component="a" 
                href="/privacy"
                data-aos="fade-up">
                  {t('policy.title') }
              </Typography>
              <Typography variant='caption' data-aos="fade-up">{t('policy.footerDescription')}</Typography>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
