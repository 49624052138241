import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import markerImage from 'assets/maps2.png';

import GoogleMapReact from 'google-map-react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  marker:{
    position: 'absolute',
  transform: 'translate(-50%, -50%)'
  }
}));

const YOUR_KEY = "AIzaSyClcOX5Tut1uJylikpNbFzkW_qpiBFjNPM";

/**
 * Component to display the map
 *
 * @param {Object} props
 */
const TheMap = props => {
  const { zoom, center, pin, className, ...rest } = props;
  const {lat,lng} = center;
  // const LeafIcon = L.Icon.extend({
  //   options: {}
  // });
  // const blueIcon = new LeafIcon({
  //     iconUrl:markerImage
  //   })
  //  //  Use the state hook:
  // const [icon, setIcon] = React.useState(blueIcon);

  const classes = useStyles();
    const Marker = ({ text }) => <div className={classes.marker}>{text}</div>;

  React.useEffect(() => {
    const L = require('leaflet');
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
          iconUrl: markerImage,
           iconRetinaUrl: null,
           shadowUrl: null,
    });
  });
  
if (typeof window === 'undefined') {
    return null;
  }

  // const ReactMap = require('react-leaflet').Map;
  // const TileLayer = require('react-leaflet').TileLayer;
  // const Marker = require('react-leaflet').Marker;
  // const Popup = require('react-leaflet').Popup;


  // mapTest = ()=>  
  // <Map
  //     zoom={zoom}
  //     center={center}
  //     className={clsx('map', classes.root, className)}
  //     {...rest}
  //   >
  //     <TileLayer
  //       className="map__tile-layer"
  //       detectRetina={true}
  //       attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  //       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  //     />
  //     {pin &&
  //       <Marker
  //           className="map__marker"
  //           position={pin}
  //           // icon={icon}
  //         >
  //          <Popup>
  //           3 Place Ville-Marie 
  //          </Popup>
  //       </Marker>
  //     }
  //   </Map>

  // if (typeof window === 'undefined') {
  //   return null;
  // }

  return (
    // <p>meow</p>

    <GoogleMapReact
      defaultZoom={zoom}
      defaultCenter={center}
      bootstrapURLKeys={{
    key: [YOUR_KEY],
  }}
      className={clsx('map', classes.root, className)}
      {...rest}
    >
{/*      <TileLayer
        className="map__tile-layer"
        detectRetina={true}
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />*/}



    </GoogleMapReact>

    // <ReactMap
    //   zoom={zoom}
    //   center={center}
    //   className={clsx('map', classes.root, className)}
    //   {...rest}
    // >
    //   <TileLayer
    //     className="map__tile-layer"
    //     detectRetina={true}
    //     attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    //     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //   />
    //   {pin &&
    //     <Marker
    //         className="map__marker"
    //         position={pin}
    //         // icon={blueIcon}
    //       >
    //        <Popup>
    //         3 Place Ville-Marie 
    //        </Popup>
    //     </Marker>
    //   }
    // </ReactMap>
  );
};

Map.defaultProps = {
  zoom: 10,
  center: [0, 0],
};

Map.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Map zoom
   */
  zoom: PropTypes.node,
  /**
   * Map center
   */
  center: PropTypes.object.isRequired,
  /**
   * data of the locations. Example: [{ location: { x: number, y: number } }]
   */
  pin: PropTypes.object,
};

export default TheMap;
