/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Grid,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listItemLink: {
    textDecoration: 'none',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  divider: {
    width: '100%',
  },
}));

const SidebarNav = props => {
  const { onClose, className, ...rest } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={onClose}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.listItem}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href='/aboutUs'
            >
              {t('header.WhyEmbarq')}
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href="/what-we-do"
            >
             {t('header.Business')}
            </Typography>
          </ListItem>

          <ListItem className={classes.listItem}>
           
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href="/how-it-works"
            >
               {t('header.Engineer')}
            </Typography>
            </ListItem>
          <ListItem className={classes.listItem}>
          <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href="/contact"
             >
              Contact
            </Typography>
          </ListItem>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            xs={12}
            md={4}
          >
           <div className={classes.languageContainer}>
          {i18n.languages.map((lng) => (
            <Button aria-haspopup="false"   
              onClick={(e) => {
                e.preventDefault();
                i18n.changeLanguage(lng);
              }} 
              className={classes.listItemLang}
              style={{ textDecoration: i18n.resolvedLanguage === lng ? 'underline' : 'none' }}
            >
              <Typography
                variant="overline"
                color="textSecondary"
                lang={lng}
              >
                {lng}
              </Typography>
            </Button>
          ))}
        </div>
           </Grid>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default SidebarNav;
