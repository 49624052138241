import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
 import translationEN from "../locales/en/translation.json";
 import translationFR from "../locales/fr/translation.json";

 const resources = {
   en: {
     translation: translationEN
   },
   fr: {
     translation: translationFR
   }
 };

i18next.use(LanguageDetector).init({
    fallbackLng: 'en',
    resources,
    ns: ['translation'],
    defaultNS: 'translation',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        wait: true,
    },
});

i18next.languages = ['fr', 'en'];

export default i18next;