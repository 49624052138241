import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { graphql} from 'gatsby';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Section } from 'components/organisms';
import {
  useMediaQuery,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
  Button,
} from '@material-ui/core';
import { Image, Icon, IconText } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {
    // position: 'relative',
     background: theme.palette.primary.dark,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  hero: {
    // backgroundColor: theme.palette.alternate.main,
    // borderRadius: theme.spacing(2),
    padding: theme.spacing(3, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 8),
    },
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  image: {
     height:'auto',
    borderRadius:'1.5rem',
    minWidth: '400',
     [theme.breakpoints.up('md')]: {
      width: 600,
    },
    [theme.breakpoints.up('xl')]: {
      width: 800,
    },
  },
  inputContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(100%)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
  },
  input: {
    background: theme.palette.background.paper,
    boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.11)',
  },
  textWhite: {
    color: theme.palette.primary.contrastText,
  },
  ctaText: {
    color: theme.palette.text.white,
    textDecoration: 'none',
    fontSize:'0.9rem'
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const { t,i18n } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.hero}>
        <Section className={classes.section}>
        <Grid
          container
          justify="space-between"
          spacing={isMd ? 4 : 2}
          direction={isMd ? 'row' : 'column-reverse'}
        >
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
            <SectionHeader
              title={
                <span className={classes.textWhite}>
                  {t('home.heroTitle')}
                </span>
                }
              subtitle={
                <span className={classes.textWhite}>
                  {t('home.heroSubtitle')}
                </span>
                }
              subtitleVariant="h4"
              ctaGroup={[
                <Typography
                  className={classes.ctaText}
                  component="a"
                  href="/what-we-do"
                >
                 {`${t('home.heroPartnerCTA')} `}<b> {`${t('home.heroSeeCTA')}  `}</b>
                 <Icon fontIconClass={'fas fa-arrow-right'} size="xxs" />
                </Typography>,
              ]}
              align="left"
              titleVariant="h3"
            />

            <Grid item xs={12} sm={6} data-aos="fade-up">
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="flex-start"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
            <Image
              src="/images/photos/ateamv2.png"
              alt="Find a Job"
              className={classes.image}
            />
          </Grid>
        </Grid>
        </Section>
      </div>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;

