import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Grid, Typography } from '@material-ui/core'
import { Section, SectionAlternate, CardBase } from 'components/organisms';
import { Image, Icon, IconText } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Hero, Faq, Process } from './components';

const useStyles = makeStyles(theme => ({
   textWhite: {
    color: theme.palette.primary.contrastText,
  },
  textBlue:{
    color: theme.palette.embarq.primary.dark,
  },
    cardBasePicture: {
    boxShadow: 'none',
    // background: theme.palette.alternate.main,
    background: "url('../images/photos/girl-computer.jpg') no-repeat center center fixed",
    backgroundSize:'cover',
    minWidth: '520px',   minHeight: '420px',
    // borderRadius: theme.spacing(1),
    // '& .card-base__content': {
    //   padding: theme.spacing(1),
    //   [theme.breakpoints.up('sm')]: {
    //     padding: theme.spacing(3),
    //   },
    // },
  },
    cardBase: {
    boxShadow: 'none',
    background: theme.palette.alternate.main,
    // borderRadius: theme.spacing(1),
    // '& .card-base__content': {
    //   padding: theme.spacing(1),
    //   [theme.breakpoints.up('sm')]: {
    //     padding: theme.spacing(3),
    //   },
    // },
  },
  ctaText: {
    color: theme.palette.text.white,
    textDecoration: 'none',
    fontSize:'0.9rem'
  },
  img:{
    display:'block'
  },
    image: {
    width:'100%',
    minWidth: '400',
     [theme.breakpoints.up('md')]: {
      width: 600,
    },
    [theme.breakpoints.up('xl')]: {
      width: 800,
    },
  },
}));

const HeroWork = (props) => {
  const { classes, t, theme} = props;
   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
      });
    return(
       <Grid
          container
          justifyContent="space-between"
          spacing={isMd ? 4 : 2}
          direction={isMd ? 'row' : 'column-reverse'}
        >
         
          <Grid
            item
            container
            alignItems="flex-start"
            sm={12}
            md={6}
            data-aos={'fade-up'}
          >
            <Image
              src="/images/photos/shakeHands.jpg"
              alt="Find a Job"
              className={classes.img}
            />
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            sm={12}
            md={5}
            data-aos={'fade-up'}
          >
        <SectionHeader
            style={{padding:'2rem'}}
              title={
                <span className={classes.textBlue}>
                  {t('businesses.statementTitle')}
                </span>
                }
              subtitle={
                <span className={classes.textBlue}>
                  {t('businesses.statement')}
                </span>
                }
              subtitleVariant="h6"
              titleVariant="h4"
            />
          </Grid>
        </Grid>
    )
}


const Businesses = () => {

  const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
      });
 
  return (
    <div> 
      <SectionAlternate style={{background: theme.palette.embarq.primary.dark}}>
        <Hero />
      </SectionAlternate>
      <Section >
      <HeroWork classes={classes} t={t} theme={theme}/>
         {/*<Grid
          container
          justifyContent="space-between"
          spacing={isMd ? 4 : 2}
          direction={isMd ? 'row' : 'column-reverse'}
        >
         
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
            <Image
              src="/images/photos/shakeHands.jpg"
              alt="Find a Job"
              className={classes.image}
            />
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="flex-start"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
        <SectionHeader
            style={{padding:'2rem'}}
              title={
                <span className={classes.textBlue}>
                  {t('businesses.statementTitle')}
                </span>
                }
              subtitle={
                <span className={classes.textBlue}>
                  {t('businesses.statement')}
                </span>
                }
              subtitleVariant="h6"
              
              titleVariant="h4"
            />
          </Grid>
        </Grid>*/}
      </Section>
      <SectionAlternate style={{background: theme.palette.embarq.accent.main}}>
        <Grid container >
         <SectionHeader
          title={
            <span className={classes.textWhite}>
              {t('businesses.title')}
            </span>
            }
          align="left"
          titleVariant="h4"
        />
        <Grid
          item
          container
          alignItems="center"
          direction="column"
          xs={12}
          md={8}
          data-aos="fade-up"
        >
          <Typography variant='h6' style={{textAlign: "justify"}} className={classes.textWhite} > {t('businesses.statement2')}</Typography>
        </Grid>
      </Grid>
    </SectionAlternate>
    <Section >
      <Process/>
    </Section>  
  </div>
    )
}

export default Businesses;