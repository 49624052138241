import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section, SectionAlternate, CardBase } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  formContainer:{
    textAlign:'justify',
  },
  section: {
   background: theme.palette.embarq.primary.dark
  },
  title:{
    color: theme.palette.embarq.neutral.dark,
    marginTop:'2rem',
    marginBottom:'1rem',
  },
    subtitle:{
    color: theme.palette.embarq.neutral.dark,
    marginTop:'1.3rem',
    marginBottom:'0.5rem',
  },
  body:{
    marginBottom:'0.5rem',
  },
   textWhite: {
    color: theme.palette.primary.contrastText,
  },
  sectionAlt: {
    backgroundImage: `linear-gradient(180deg, ${theme.palette.embarq.neutral.light} 60%, ${theme.palette.background.paper} 0%)`,
    padding:0
  },
  subsection: {
    paddingTop: '0.3rem',
    paddingBottom: 0,
    paddingLeft: '2rem',
    paddingRight:'2rem',
  },
   accord:{
    boxShadow:'none',
    width:'100%',
  },
  accordSumm:{
    color: theme.palette.embarq.secondary.dark
  },
  accordTitle:{
    marginBottom:'3rem',
  },
  lastSection: {
    paddingTop:'2rem',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.embarq.accent.main} 50%, ${theme.palette.background.paper} 0%)`,
  }
}));

const Fr = () => {
  const classes = useStyles();
  return (
      <div className={classes.formContainer}>
        <SectionAlternate className={classes.section}>
         <SectionHeader
              title={
                <span className={classes.textWhite}>
                   Politique de confidentialité
                </span>
                }
              subtitle={
                <span className={classes.textWhite}>
                  La présente Politique de confidentialité décrit nos politiques et procédures en matière de collecte, d'utilisation et de divulgation de vos informations lorsque vous utilisez le Service et vous informe de vos droits en matière de confidentialité et de la manière dont la loi vous protège. Nous utilisons Vos données personnelles pour fournir et améliorer le Service. En utilisant le Service, vous acceptez la collecte et l'utilisation des informations conformément à la présente Politique de confidentialité.
                </span>
                }
              align="left"
              titleVariant="h3"
          />
          <Typography variant='caption' className={classes.textWhite}>
            Dernière mise à jour: 15 Février, 2023
          </Typography>
        </SectionAlternate>
        <Section >
          <Typography variant='h4' className={classes.title}>
            Lois applicables
          </Typography>
          <Typography variant='body1' className={classes.body}>
            Cette politique est conforme aux lois énoncées dans la <em>loi sur la protection des renseignements personnels et les documents électroniques </em> (LPRPDE).
          </Typography>
          <Typography variant='body1' className={classes.body}>
            Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à la <em>California Consumer Privacy Act (CCPA)</em>. S’il y a des incohérences entre ce document et la <em>CCPA</em>, la législation de l’État s’appliquera. Si nous constatons des incohérences, nous modifierons notre politique pour nous conformer à la loi pertinente.
          </Typography>
           <Typography variant='h4' className={classes.title}>
            Interprétation et définitions
          </Typography>
          <Typography variant='h5' className={classes.subtitle}>
            Interprétation
          </Typography>
          <Typography variant='body1' className={classes.body}>
            Les définitions suivantes ont la même signification, qu'elles apparaissent au singulier ou au pluriel.
          </Typography>
          <Typography variant='h5' className={classes.subtitle}>
            Définitions
           </Typography>
          <Typography variant='body1' className={classes.body}>
            Aux fins de la présente politique de confidentialité :
          </Typography>
          <ul>
            <li>
             <strong>Compte</strong> désigne un compte unique créé pour vous afin d'accéder à notre service ou à certaines parties de notre service.
            </li>
  
            <li>
             <strong>Société</strong> (désignée par "la société", "nous", "notre" ou "nos" dans le présent contrat) fait référence à AC781 INC. 400-3 Place Ville-Marie Montréal Québec H3B 2E3 .
            </li>
            <li>
             <strong>Cookies</strong> sont de petits fichiers qui sont placés sur votre ordinateur, votre appareil mobile ou tout autre appareil par un site web, contenant les détails de votre historique de navigation sur ce site web parmi ses nombreuses utilisations.
            </li>
            <li>
             <strong>Pays</strong> refers to: Quebec,  Canada
            </li>
            <li>
             <strong>Appareil</strong> désigne tout appareil permettant d'accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.
            </li>
            <li>
             <strong>Les données personnelles</strong> sont toute information qui se rapporte à un individu identifié ou identifiable.
            </li>
            <li>
             <strong>Service</strong> réfère au site web.
            </li>
            <li>
             <strong>Prestataire de services</strong> désigne toute personne physique ou morale qui traite les données pour le compte de la Société. Il s'agit de sociétés tierces ou de personnes employées par la Société pour faciliter le Service, pour fournir le Service au nom de la Société, pour effectuer des services liés au Service ou pour aider la Société à analyser la façon dont le Service est utilisé.
            </li>
            <li>
             <strong>Données d'utilisation</strong> désignent les données collectées automatiquement, soit générées par l'utilisation du service, soit provenant de l'infrastructure du service elle-même (par exemple, la durée de la visite d'une page).
            </li>
            <li>
             <strong>Site web</strong> fait référence à embarq, accessible à partir de <a href="embarq.tech" rel="external nofollow noopener" target="_blank">embarq.tech</a>.
            </li>
            <li>
             <strong>Vous</strong> désigne la personne qui accède au Service ou l'utilise, ou la société, ou toute autre entité juridique au nom de laquelle cette personne accède au Service ou l'utilise, selon le cas.
            </li>
          </ul>
        </Section>
        <SectionAlternate className={classes.sectionAlt} >
          <CardBase className={classes.cardBase} align="left" >
          <Typography variant='h4' className={classes.accordTitle}>
            Collecte et utilisation de vos données personnelles
          </Typography>
           <Accordion className={classes.accord}>
             <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className={classes.accordSumm}
                id="panel1a-header"
              >
                <Typography variant='h5' className={classes.subtitle}>
                  Types de données collectées 
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Section className={classes.subsection}>
                <Typography variant='h6' className={classes.subtitle6}>
                  Données personnelles
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Lors de l'utilisation de notre site, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou pour vous identifier. Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site. Nous n’utiliserons pas vos données au-delà de ce que nous divulguerons. Les informations personnellement identifiables peuvent inclure, mais ne sont pas limitées à :
                </Typography>
                <ul>
                  <li>
                   Adresse courriel
                  </li>
                  <li>
                   Données d'utilisation
                  </li>
                </ul>
                <Typography variant='h6' className={classes.subtitle6}>
                  Utilisation des données
                </Typography>
                <Typography variant='body1' className={classes.body}>
                 Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                 Les données d'utilisation peuvent inclure des informations telles que Votre type de navigateur , la version du navigateur, les pages de notre Service que Vous visitez, l'heure et la date de Votre visite, le temps passé sur ces pages, les identifiants uniques des appareils et autres données de diagnostic.
                 </Typography>
                 <Typography variant='body1' className={classes.body}>
                 Lorsque vous accédez au Service par ou via un appareil mobile, nous pouvons recueillir certaines informations automatiquement, y compris, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil et d'autres données de diagnostic.
                 </Typography>
                <Typography variant='body1' className={classes.body}>
                 Nous pouvons également recueillir les informations que votre navigateur envoie lorsque vous visitez notre service ou lorsque vous accédez au service par ou via un appareil mobile.
                </Typography>
                <Typography variant='h6' className={classes.subtitle6}>
                  Technologies de suivi et cookies
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur Notre Service et stocker certaines informations. Les technologies de suivi utilisées sont des balises, des marqueurs et des scripts pour collecter et suivre des informations et pour améliorer et analyser Notre Service. Les technologies que nous utilisons peuvent inclure :
                </Typography>
                <ul>
                  <li><strong>Cookies</strong> Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.</li>
                  <li><strong>Balises Web.</strong> Certaines sections de notre Service et nos courriels peuvent contenir de petits fichiers électroniques connus sous le nom de balises web (également appelées gifs clairs, balises pixel et gifs à pixel unique) qui permettent à la Société, par exemple, de compter les utilisateurs qui ont visité ces pages ou ouvert un courriel et pour d'autres statistiques de site web connexes (par exemple, enregistrer la popularité d'une certaine section et vérifier l'intégrité du système et du serveur).</li>
                </ul>
                <Typography variant='body1' className={classes.body}>
                  Cookies peuvent être &quot;Persistants&quot; ou de &quot;Session&quot;. Les Cookies persistants restent sur votre ordinateur personnel ou votre appareil mobile lorsque vous vous déconnectez, tandis que les cookies de session sont supprimés dès que vous fermez votre navigateur web. Vous pouvez en savoir plus sur les cookies sur <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank">TermsFeed website</a> article.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                   Nous utilisons des cookies de session et des cookies persistants aux fins énoncées ci-dessous :
                </Typography>
                <ul>
                  <li>
                     <Typography variant='body1' className={classes.body}>
                      <strong>Cookies fonctionnels</strong>
                     </Typography>
                     <Typography variant='body1' className={classes.body}>
                      Type: Cookies de Session
                     </Typography>
                     <Typography variant='body1' className={classes.body}>
                      Objectif : Ces cookies sont essentiels pour Vous fournir les services disponibles par le biais du site Web et pour Vous permettre d'utiliser certaines de ses fonctionnalités. Ils aident à authentifier les utilisateurs et à prévenir l'utilisation frauduleuse des comptes d'utilisateurs. Sans ces Cookies, les services que Vous avez demandés ne peuvent pas être fournis, et Nous utilisons ces Cookies uniquement pour Vous fournir ces services.
                     </Typography>
                    </li>
                  <li>
                    <Typography variant='body1' className={classes.body}>
                      <strong>Politique / Avis d'acceptation des cookies</strong>
                    </Typography>
                    <Typography variant='body1' className={classes.body}>
                      Type: Cookies persistants
                    </Typography>
                     <Typography variant='body1' className={classes.body}>
                      Objectif : Ces cookies identifient si les utilisateurs ont accepté l'utilisation de cookies sur le site Web.
                       </Typography>
                   </li>
                  <li>
                    <Typography variant='body1' className={classes.body}>
                      <strong>Cookies fonctionnels</strong>
                    </Typography>
                    <Typography variant='body1' className={classes.body}>
                      Type: Cookies persistants
                    </Typography>
                    <Typography variant='body1' className={classes.body}>
                      Objectif : Ces cookies nous permettent de nous souvenir des choix que vous faites lorsque vous utilisez le site Web, comme la mémorisation de vos données de connexion ou de vos préférences linguistiques. L'objectif de ces cookies est de vous offrir une expérience plus personnelle et de vous éviter de devoir saisir à nouveau vos préférences chaque fois que vous utilisez le site Web.
                    </Typography>
                   </li>
                </ul>
                <Typography variant='body1' className={classes.body}>
                 Pour plus d'informations sur les cookies que nous utilisons et vos choix en matière de cookies, veuillez consulter notre politique en matière de cookies ou la section Cookies de notre politique de confidentialité.
                </Typography>
              </Section>
            </AccordionDetails>              
          </Accordion>
           <Accordion className={classes.accord}>
             <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className={classes.accordSumm}
                id="panel1a-header"
              >
                <Typography variant='h5' className={classes.subtitle}>
                 Utilisation de vos données personnelles
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Section className={classes.subsection}>
                <Typography variant='body1' className={classes.body}>
                 La société peut utiliser les données personnelles aux fins suivantes :
                </Typography>
                <ul>
                  <li>
                    <strong>Pour ournir et maintenir notre service</strong>, notamment pour contrôler l'utilisation de notre service.
                  </li>
                  <li>
                    <strong>Pour gérer votre compte :</strong> pour gérer votre inscription en tant qu'utilisateur du Service. Les Données Personnelles que vous fournissez peuvent vous donner accès à différentes fonctionnalités du Service qui vous sont accessibles en tant qu'utilisateur enregistré.
                  </li>
                  <li>
                    <strong>Pour vous contacter</strong> Vous contacter par courrier électronique, appels téléphoniques, SMS ou autres formes équivalentes de communication électronique, telles que les notifications push d'une application mobile concernant les mises à jour ou les communications informatives liées aux fonctionnalités, produits ou services contractuels, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.
                  </li>
                  <li>
                    <strong>Pour vous fournir</strong> des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons et qui sont similaires à ceux que vous avez déjà achetés ou sur lesquels vous vous êtes renseigné, sauf si vous avez choisi de ne pas recevoir ces informations.
                  </li>
                  <li>
                    <strong>Pour gérer vos demandes : </strong>Pour assister et gérer les demandes que vous nous adressez.
                  </li>
                  <li>
                    <strong>Pour les transferts d'entreprises :</strong> Nous pouvons utiliser Vos informations pour évaluer ou réaliser une fusion, un désinvestissement, une restructuration, une réorganisation, une dissolution ou toute autre vente ou transfert de tout ou partie de Nos actifs, que ce soit dans le cadre d'une poursuite d'activité ou d'une faillite, d'une liquidation ou d'une procédure similaire, dans laquelle les Données personnelles que Nous détenons sur les utilisateurs de nos Services font partie des actifs transférés.
                  </li>
                  <li>
                    <strong>À d'autres fins</strong> : Nous pouvons utiliser Vos informations à d'autres fins, telles que l'analyse des données, l'identification des tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et l'évaluation et l'amélioration de notre Service, de nos produits, de nos services, de notre marketing et de votre expérience.
                  </li>
                </ul>
                <Typography variant='body1' className={classes.body}>
                  Nous pouvons partager vos informations personnelles dans les situations suivantes :
                </Typography>
                <ul>
                  <li><strong>Avec les fournisseurs de services :</strong> Nous pouvons partager vos informations personnelles avec les fournisseurs de services pour surveiller et analyser l'utilisation de notre service, pour vous contacter.</li>
                  <li><strong>Pour les transferts d'entreprise :</strong> Nous pouvons partager ou transférer Vos informations personnelles dans le cadre ou pendant les négociations d'une fusion, d'une vente d'actifs de la Société, d'un financement ou d'une acquisition de tout ou partie de Notre entreprise à une autre société.</li>
                 <li><strong>Si la loi l'exige </strong>Nous pouvons divulguer vos informations personnelles avec les représentant de la loi, si elle est requise pour toute procédure judiciaire ou pour prouver ou protéger nos droits légaux </li>
                 <li><strong>Avec votre consentement: </strong>Nous pouvons divulguer vos informations personnelles à toute autre fin avec votre consentement.</li>
                </ul>
              </Section>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accord}>
           <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className={classes.accordSumm}
              id="panel1a-header"
            >
              <Typography variant='h5' className={classes.subtitle}>
                Conservation de vos données personnelles
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Section className={classes.subsection}>
                <Typography variant='body1' className={classes.body}>
                  La Société ne conservera vos Données personnelles que pendant la durée nécessaire aux fins énoncées dans la présente Politique de confidentialité. Nous conserverons et utiliserons Vos Données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour nous conformer aux lois applicables), résoudre les litiges et appliquer nos accords et politiques juridiques.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  La Société conservera également les Données d'utilisation à des fins d'analyse interne. Les Données d'utilisation sont généralement conservées pendant une période plus courte, sauf si ces données sont utilisées pour renforcer la sécurité ou améliorer la fonctionnalité de Notre Service, ou si Nous sommes légalement obligés de conserver ces données pendant des périodes plus longues.
                </Typography>
              </Section> 
            </AccordionDetails>
          </Accordion>   
          <Accordion className={classes.accord}>
           <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className={classes.accordSumm}
              id="panel1a-header"
            >
              <Typography variant='h5' className={classes.subtitle}>
                Transfert de vos données personnelles
              </Typography>
            </AccordionSummary>
            <AccordionDetails>  
              <Section className={classes.subsection}>
                <Typography variant='body1' className={classes.body}>
                  Vos informations, y compris les Données personnelles, sont traitées dans les bureaux d'exploitation de la Société et dans tout autre lieu où se trouvent les parties impliquées dans le traitement. Cela signifie que ces informations peuvent être transférées vers - et conservées sur - des ordinateurs situés en dehors de Votre état, province, pays ou autre juridiction gouvernementale où les lois de protection des données peuvent différer de celles de Votre juridiction.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Votre consentement à la présente politique de confidentialité, suivi de votre soumission de ces informations, représente votre accord à ce transfert.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  La Société prendra toutes les mesures raisonnablement nécessaires pour s'assurer que Vos données sont traitées en toute sécurité et conformément à la présente politique de confidentialité et aucun transfert de Vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il n'y ait des contrôles adéquats en place, y compris la sécurité de Vos données et autres informations personnelles.
                </Typography>
               </Section> 
              </AccordionDetails>
            </Accordion>
          <Accordion className={classes.accord}>
           <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className={classes.accordSumm}
              id="panel1a-header"
            >  
              <Typography variant='h5' className={classes.subtitle}>
                Suppression de vos données personnelles
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Section className={classes.subsection}>
                <Typography variant='body1' className={classes.body}>
                  Vous avez le droit de supprimer ou de demander que Nous vous aidions à supprimer les données personnelles que Nous avons recueillies à votre sujet.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Notre service peut vous donner la possibilité de supprimer certaines informations vous concernant à partir du service.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Vous pouvez mettre à jour, modifier ou supprimer vos informations à tout moment en vous connectant à votre compte, si vous en avez un, et en consultant la section des paramètres du compte qui vous permet de gérer vos informations personnelles. Vous pouvez également nous contacter pour demander l'accès, la correction ou la suppression de toute information personnelle que vous nous avez fournie.
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Veuillez noter, cependant, que Nous pouvons avoir besoin de conserver certaines informations lorsque nous avons une obligation légale ou une base légitime pour le faire.
                </Typography>
              </Section>
            </AccordionDetails>
          </Accordion>
           <Accordion className={classes.accord}>
             <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className={classes.accordSumm}
                id="panel1a-header"
              >
              <Typography variant='h5' className={classes.subtitle}>
                Divulgation de vos données personnelles
              </Typography>
            </AccordionSummary>
            <AccordionDetails>  
              <Section className={classes.subsection}>
                <Typography variant='h6' className={classes.subtitle6}>
                  Transactions commerciales
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Si la société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous fournirons un avis avant que Vos Données personnelles ne soient transférées et ne soient soumises à une Politique de confidentialité différente.
                </Typography>
                <Typography variant='h6' className={classes.subtitle6}>
                  Application de la loi
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  Dans certaines circonstances, la Société peut être tenue de divulguer Vos Données personnelles si la loi l'exige ou en réponse à des demandes valables des autorités publiques (par exemple, un tribunal ou une agence gouvernementale).
                </Typography>
                <Typography variant='h6' className={classes.subtitle6}>
                  Autres exigences légales
                </Typography>
                <Typography variant='body1' className={classes.body}>
                  La Société peut divulguer vos données personnelles en croyant de bonne foi qu'une telle action est nécessaire pour :
                </Typography>
                <ul>
                  <li>Se conformer à une obligation légale</li>
                  <li>Protéger et défendre les droits ou les biens de la société</li>
                  <li>Prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec le service</li>
                  <li>Protéger la sécurité personnelle des utilisateurs du service ou du public</li>
                  <li>Protéger contre la responsabilité juridique</li>
                </ul>
              </Section>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accord}>
             <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className={classes.accordSumm}
                id="panel1a-header"
              >
            <Typography variant='h5' className={classes.subtitle}>
              Sécurité de vos données personnelles
            </Typography>
          </AccordionSummary>
          <AccordionDetails>  
            <Section className={classes.subsection}>
              <Typography variant='body1' className={classes.body}>
                La sécurité de vos données personnelles est importante pour Nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est sûre à 100 %. Bien que Nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger Vos Données Personnelles, Nous ne pouvons pas garantir leur sécurité absolue.
              </Typography>
             </Section> 
           </AccordionDetails>
          </Accordion>
          </CardBase>
       </SectionAlternate> 
        <SectionAlternate className={classes.lastSection}>
          <CardBase align="left" >
            <Typography variant='h4' className={classes.title}>
              Confidentialité des mineurs
            </Typography>
            <Typography variant='body1' className={classes.body}>
              Notre service ne s'adresse pas aux personnes âgées de moins de 18 ans. Nous ne recueillons pas sciemment des informations personnellement identifiables auprès de personnes âgées de moins de 18 ans.
              Si nous devons nous appuyer sur le consentement comme base juridique pour traiter vos informations et que votre pays exige le consentement d'un parent, nous pouvons demander le consentement de votre parent avant de collecter et d'utiliser ces informations.
            </Typography>
            <Typography variant='h4' className={classes.title}>
              Liens vers d'autres sites
            </Typography>
            <Typography variant='body1' className={classes.body}>
              Notre Service peut contenir des liens vers d'autres sites web qui ne sont pas exploités par Nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez.
              Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers.
            </Typography>
            <Typography variant='h4' className={classes.title}>
              Modifications de la présente politique de confidentialité
            </Typography>
            <Typography variant='body1' className={classes.body}>
              Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la nouvelle politique de confidentialité sur cette page. Nous vous informerons par e-mail et/ou par un avis bien visible sur notre service, avant que la modification ne prenne effet et nous mettrons à jour la date de " dernière mise à jour " en haut de la présente politique de confidentialité. Nous vous conseillons de consulter régulièrement la présente politique de confidentialité pour prendre connaissance des modifications éventuelles. Les modifications apportées à la présente politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page.
            </Typography>
            <Typography variant='h4' className={classes.title}>
             Contactez-nous
            </Typography>
            <Typography variant='body1' className={classes.body}>
              Si vous avez des questions sur cette politique de confidentialité, vous pouvez nous contacter à l'adresse info@embarq.tech.
            </Typography>
          </CardBase>
        </SectionAlternate>
    </div>
  );
};

export default Fr;
