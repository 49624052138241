import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, ImageList, ImageListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
    textBlue:{
    color: theme.palette.embarq.neutral.dark,
  },
}));

const Gallery = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={ <span className={classes.textBlue}>
                  {t('about.galleryTitle')}
                </span>
                }
        subtitle={ <span className={classes.textBlue}>
                  {t('about.gallerySubtitle')}
                </span>
                }
        data-aos="fade-up"
      />
      <ImageList cellHeight={isMd ? 360 : 260} cols={4} spacing={isMd ? 24 : 8}>
        {data.map((item, index) => (
          <ImageListItem key={index} cols={isMd ? item.cols : 4 || 1}>
            <Image
              {...item.image}
              alt={item.location}
              className={classes.image}
              lazyProps={{
                width: '100%',
                height: '100%',
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

Gallery.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Gallery;
