import React from 'react';
import PropTypes from 'prop-types';
import { useTheme,makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, colors } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from 'components/molecules';
import { Icon } from 'components/atoms';
import { CardBase, DescriptionListIcon } from 'components/organisms';

const useStyles = makeStyles(theme => ({
   textWhite: {
    color: theme.palette.primary.contrastText,
  },
   textBlue:{
    color: theme.palette.embarq.neutral.dark,
    textAlign:"justify"
  },
   iconCover: {
    color: theme.palette.primary.main,
  },
  ctaText: {
    color: theme.palette.embarq.primary.dark,
    textDecoration: 'none',
    fontSize:'0.9rem'
  },
}));

const Process = props => {
  const { className, ...rest } = props;
  const { t, ready } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const steps = t('process.steps', { returnObjects: true });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={t('businesses.whatWeDo')}
        label={t('howItWorks.categoriesLabel')}
        fadeUp
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {steps.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            md={4}
            data-aos="fade-up"
          >
            <CardBase withShadow liftUp >
              <Icon 
                fontIconClass={item.icon}
                size="medium"
            
                style={{marginBottom:'1rem', color:theme.palette.primary.main}}
              />
              <SectionHeader
                title={<span className={classes.textBlue}>{item.title}</span>}
                subtitle={<span className={classes.textBlue}>{item.description1}</span>}
                subtitleVariant="body1"
                titleVariant='h6'
              />
            </CardBase>
          </Grid>
        ))}
         <Grid
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={12}
            data-aos="fade-up"
          >
            <Typography
              // variant="body1"
              className={classes.ctaText}
              component="a"
              href="/contact"
            >
              {`${t('contact.title')}. `}<b> {`${t('contact.cta')}  `}</b>
              <Icon fontIconClass={'fas fa-arrow-right'} size="xxs" />
            </Typography>
          </Grid>
      </Grid>
    </div>
  );
};

Process.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
};

export default Process;
