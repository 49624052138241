// Initialize Firestore through Firebase
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyDTVvlQcAuVhB7nz3qOy4pS3YysOPHHVvQ",
  authDomain: "embarq-eae0b.firebaseapp.com",
  projectId: "embarq-eae0b",
};


const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);