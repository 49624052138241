import React from 'react';
import {Router,Redirect} from "@reach/router";
import { NoSsr } from '@material-ui/core';

import { RouteWithLayout } from './common';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Home as HomeView,
  HowItWorks as HowItWorksView,
  ContactPage as ContactPageView,
  NotFound as NotFoundView,
  About as AboutView,
  Businesses as BusinessesView,
  PrivacyPolicy as PrivacyPolicyView
} from './views';

const Routes = () => {
  return (
    <Router >
    <Redirect  from="/" to="/home" />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={AboutView}
        exact
        layout={MainLayout}
        path="/aboutUs"
      />
      <RouteWithLayout
        component={HowItWorksView}
        exact
        layout={MainLayout}
        path="/how-it-works"
      />
      <RouteWithLayout
        component={BusinessesView}
        exact
        layout={MainLayout}
        path="/what-we-do"
      />
      <RouteWithLayout
        component={ContactPageView}
        exact
        layout={MainLayout}
        path="/contact"
      />
      <RouteWithLayout
        component={PrivacyPolicyView}
        exact
        layout={MainLayout}
        path="/privacy"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        default
      />
      
    </Router>
  );
};

export default Routes;
