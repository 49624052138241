import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import {
  AboutBottom,
  AboutMiddle,
  AboutTop,
  Advantages,
  Customers,
  Features,
  Hero,
  Jobs,
  Partners,
  Process,
  PromoNumbers,
  PromoSwiper,
  Questions,
  TrustedCompanies,
} from './components';

import {
  partners,
  process,
  features,
  jobs,
  advantages,
  companies,
  promo,
  reviews,
} from './data';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPadding: {
    padding: 'none',
  },
}));

const JobListing = () => {
  const classes = useStyles();

  return (
    <div>
      
      <Hero />
      <Process data={process} />
      <SectionAlternate>
        <Features data={features} />
      </SectionAlternate>
      <Section className={classes.sectionNoPadding}>
      </Section>
      <Divider />
    </div>
  );
};

export default JobListing;
