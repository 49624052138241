import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  textWhite:{
    color: theme.palette.embarq.primary.contrastText,
    paddingTop: '1rem'
  },
  title:{
    color: theme.palette.embarq.primary.contrastText,
    fontWeight: '700'
  },
  subtitle:{
    color: theme.palette.embarq.primary.contrastText,
    paddingTop: '1rem',
    textAlign:'justify',
  }
}));

const WhoWeAre = props => {
  const { className, ...rest } = props;
    const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={5} >
          <Typography variant='h4' className={classes.title} data-aos="fade-up">{t('about.whoTitle')}</Typography>
          <Typography variant='subtitle1' className={classes.subtitle} data-aos="fade-up">{t('about.whoDescription')}</Typography>
        </Grid>
         <Grid item xs={0} md={1}>
         </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='h4' className={classes.title} data-aos="fade-up">{t('about.nameTitle')}</Typography>
          <Typography variant='subtitle1' className={classes.subtitle} data-aos="fade-up">{t('about.nameDescription')}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
