import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Section, SectionAlternate } from 'components/organisms';
import { SectionHeader } from 'components/molecules';
import {
  Gallery,
  Hero,
  Story,
  Team,
  WhoWeAre,
} from './components';

import { team, companies, mapData, gallery } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionPartners: {
    boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
    '& .section-alternate__content': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  sectionAlt:{
    background: theme.palette.embarq.accent.main
  },

}));

const About = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Hero />
      <Section>
        <Story />
      </Section>
      <SectionAlternate className={classes.sectionAlt}>
        <WhoWeAre />
      </SectionAlternate>
     {/* <SectionAlternate className={classes.sectionAlt}>
        <SectionHeader
          style={{padding:'2rem'}}
          title={
            <span className={classes.textWhite}>
              {t('about.altTitle')}
            </span>
            }
          subtitle={
            <span className={classes.textWhite}>
              {t('about.altSubtitle')}
            </span>
            }
          subtitleVariant="h6"
          align="left"
          titleVariant="h4"
        />
      </SectionAlternate>*/}
      <Section >
        <Team data={team} />
      </Section>
      <SectionAlternate>
        <Gallery data={gallery} />
      </SectionAlternate>
    </div>
  );
};

export default About;
