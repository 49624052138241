import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid,Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: 420,
  },
  listItemText:{
    paddingTop:'1rem',
    textAlign:'justify',
    color: theme.palette.embarq.neutral.dark,
  },
  textBlue:{
    color: theme.palette.embarq.neutral.dark,
  },
}));

const Story = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
        >
          <div>
            <SectionHeader
            data-aos={'fade-up'}
              title={
                <span className={classes.textBlue}>
                  {t('about.storyTitle')}
                </span>
                }
              align="left"
              disableGutter
             
            />
             <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.listItemText}
            >
             {t('about.storyDescription')}
            </Typography>
                         <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.listItemText}
            >
             {t('about.storyDescription2')}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          container
          justify={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          xs={12}
          md={6}
          
        >
          <Image
            src="https://assets.maccarianagency.com/the-front/illustrations/working-on-sofa.svg"
            alt="Our story"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
