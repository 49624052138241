import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import { Section } from 'components/organisms';
import { Fr, En } from './components';

const PrivacyPolicy = () => {
  const {i18n} = useTranslation();
  const currentLanguage = i18n.resolvedLanguage ?? 'en';

  return (
    <div>
      <Divider />
      
        {currentLanguage === 'fr' ? < Fr/> : < En/>}
 
    </div>
  );
};

export default PrivacyPolicy;
