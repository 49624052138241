import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Grid, Typography } from '@material-ui/core'
import { Section, SectionAlternate, CardBase } from 'components/organisms';
import { Image, Icon, IconText } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import Newsletter from '../Newsletter';
import { Hero, Faq, Categories } from './components';

const useStyles = makeStyles(theme => ({
   textWhite: {
    color: theme.palette.primary.contrastText,
  },
  textBlue:{
    color: theme.palette.embarq.primary.dark,
  },
    cardBasePicture: {
    boxShadow: 'none',
    background: "url('../images/photos/girl-computer.jpg') no-repeat center center fixed",
    backgroundSize:'cover',
    minWidth: '520px',   minHeight: '420px',
  },
    cardBase: {
    boxShadow: 'none',
    background: theme.palette.alternate.main,
  },
  ctaText: {
    color: theme.palette.text.white,
    textDecoration: 'none',
    fontSize:'0.9rem'
  },imgContainer:{
      position: 'relative',
  },
    image: {
      // borderRadius:'3rem',
      width:'100%',
    minWidth: '400',
     [theme.breakpoints.up('md')]: {
      width: 600,
    },
    [theme.breakpoints.up('xl')]: {
      width: 800,
    },
  },
}));

const HeroWork = (props) => {
  const { classes, t, theme} = props;
   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
      });
    return(
       <Grid
          container
          justify="space-between"
          spacing={isMd ? 4 : 2}
          direction={isMd ? 'row' : 'column-reverse'}
        >
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
          <CardBase className={classes.cardBase} liftUp>

            <SectionHeader
            style={{padding:'2rem'}}
              title={
                <span className={classes.textBlue}>
                  {t('howItWorks.heroWorkTitle')}
                </span>
                }
              subtitle={
                <span className={classes.textBlue}>
                  {t('howItWorks.heroWorkSubtitle')}
                </span>
                }
              subtitleVariant="h5"
              align="left"
              titleVariant="h4"
            />
          </CardBase>
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="flex-start"
            xs={12}
            md={6}
            data-aos={'fade-up'}
            className={classes.imgContainer}
          >
           <Image
            src="../images/photos/girl-computer.jpg"
            alt="Our story"
            className={classes.image}
          />
            {/*<CardBase className={classes.cardBasePicture} liftUp/>*/}
          </Grid>
        </Grid>
    )
}


const HowItWorks = () => {

  const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();
 
  return (
    <div> 
      <SectionAlternate style={{background: theme.palette.embarq.primary.dark}}>
        <Hero />
      </SectionAlternate>
      <Section >
        <HeroWork classes={classes} theme={theme} t={t}/>
      </Section>
      <SectionAlternate style={{background: theme.palette.embarq.accent.main}}>
          <SectionHeader
            style={{padding:'2rem'}}
              title={
                <span className={classes.textWhite}>
                  {t('howItWorks.titleRoles')}
                </span>
                }
              subtitle={
                <span className={classes.textWhite}>
                  {t('howItWorks.descriptionRoles')}
                </span>
                }
              subtitleVariant="h6"
              align="left"
              titleVariant="h4"
            />
      </SectionAlternate>
      <Section >
        <Categories/>
      </Section>
       <SectionAlternate style={{backgroundImage: `linear-gradient(180deg, ${theme.palette.embarq.neutral.light} 50%, ${theme.palette.background.paper} 0%)`}} >
        <Faq/>
      </SectionAlternate>
       <Section>
        <Newsletter />
      </Section>
    </div>
    )
}

export default HowItWorks;