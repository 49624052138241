import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Divider,useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Toolbar,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Collapse,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import { Image } from 'components/atoms';

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const useStyles = makeStyles(theme => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
    navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap:'1rem'
  },
  menuContainer: {
     display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
    languageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  toolbar: {
    // maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 0 0 0',
    padding: theme.spacing(0, 2),
       // backgroundColor: theme.palette.embarq.neutral.light

  },
  listItem: {
    cursor: 'pointer',
    paddingTop: '0.5rem',
    paddingBottom: '1rem',
     '&:hover': {
      textUnderlinePosition: 'under',
      textDecoration:'underline',
      // textDecorationThickness:'0.1rem',
      // textDecorationColor:theme.palette.grey[500],
      textDecorationStyle:'solid',
    },
  },
  listItemText: {
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  listItemLang: {
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    minWidth:'0px',
    padding:'none'
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  logoContainer: {
    width: 80,
    height: 80,
    marginRight:'2rem',
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 133,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));

const Topbar = props => {
  const { onSidebarOpen, ...rest } = props;
  const { t ,i18n} = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

  };

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
     <Grid
          container
          justifyContent="flex-start"
          spacing={isMd ? 4 : 2}
          direction={isMd ? 'row' : 'column-reverse'}
        >
     <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            xs={12}
            md={2}
          >
      <div className={classes.logoContainer}>
        <a href="/home" title="embarq">
          <Image
            className={classes.logoImage}
            src="/images/logos/Logo-grad.svg"
            alt="embarq"
            lazy={false}
          />
        </a>
      </div>
      </Grid>
      <Hidden smDown>
     
     <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            md={4}
            data-aos={'fade-up'}
          >
        <List className={classes.navigationContainer}>
          <ListItem className={classes.listItem}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
               href="/what-we-do"
            >
             {t('header.Business')}
            </Typography>
          </ListItem>

          <ListItem className={classes.listItem}>
           
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href="/how-it-works"
            >
               {t('header.Engineer')}
            </Typography>
            </ListItem>
             <ListItem className={classes.listItem}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href='/aboutUs'
            >
              {t('header.WhyEmbarq')}
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
          <Typography
              variant="body1"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href="/contact"
             >
              Contact
            </Typography>
          </ListItem>
        </List>
        </Grid>
        <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            xs={12}
            md={4}
          >
        <div className={classes.languageContainer}>
          {i18n?.languages?.map((lng) => (
            <Button aria-haspopup="false"   
              onClick={(e) => {
                e.preventDefault();
                i18n.changeLanguage(lng);
              }} 
              className={classes.listItemLang}
              style={{ textDecoration: i18n.resolvedLanguage === lng ? 'underline' : 'none' }}
            >
              <Typography
                variant="overline"
                color="textSecondary"
                lang={lng}
              >
                {lng}
              </Typography>
            </Button>

          ))}
        </div>
        </Grid>
        </Hidden>
        </Grid>

      <Hidden mdUp>
        <IconButton
          className={classes.iconButton}
          onClick={onSidebarOpen}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
