import React from 'react';
import { graphql } from 'gatsby';
import { withTrans } from '../../i18n/withTrans';
import Routes from '.././Routes';

const App = () => {

        return(
        <Routes />
        );
}

export default withTrans(App);