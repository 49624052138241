import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Button, colors, NoSsr, Typography,useMediaQuery  } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { DescriptionListIcon, Section, CardBase } from 'components/organisms';
import { Icon, Image } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import { SectionAlternate } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  iconCover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      width: 80,
      height: 80,
      fontSize: 40,
    },
  },
   hero: {
    borderRadius: theme.spacing(2),
    margin: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 8),
    },
  },
  image: {
    height:'auto',
    borderRadius:'3rem',
    minWidth: '500',
     [theme.breakpoints.up('md')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      width: 600,
    },
    [theme.breakpoints.up('xl')]: {
      width: 800,
    },
  },
  textBlue:{
    color: theme.palette.embarq.primary.dark,
  },
  backgroundOrange:{
    backgroundImage: `linear-gradient(180deg, ${theme.palette.embarq.accent.main} 50%, ${theme.palette.background.paper} 0%)`,
  }
}));

const HeroProcess = props => {
  const { classes,t } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
        className={classes.hero}
      >
       <Grid
          item
          container
          justify="center"
          alignItems="flex-start"
          xs={12}
          md={6}
          data-aos={'fade-up'}
          className={classes.section}
        >
          <Image
            src="/images/photos/img52.jpg"
            alt="Find a Job"
            className={classes.image}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={5}
          data-aos={'fade-up'}
        >
          <SectionHeader
            align="left"
            titleVariant="h3"
            title={
              <span className={classes.textBlue}>
                {t('process.slogan')}
              </span>
            }
          />
          <Typography variant='h6' style={{color:theme.palette.embarq.neutral.dark, textAlign:"justify"}}> {t('process.subtitle')}</Typography>
        </Grid>
      </Grid>
  );
};

const Process = props => {
  const { data, className, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const steps = t('process.steps', { returnObjects: true });

  return (
    <div className={className} {...rest}>
      <Section>
           <HeroProcess classes={classes} t={t} />
      </Section>
      
     <SectionAlternate className={classes.backgroundOrange}>
      <CardBase className={classes.cardBase} withShadow>
      <SectionHeader title={t('header.WhatWeDo')} label={t('howItWorks.categoriesTitle')}  fadeUp />
          <Grid container justify-content="space-between" spacing={4}>
            {steps.map((item, index) => (
             <Grid
                item
                container
                alignItems="center"
                direction="column"
                xs={12}
                md={4}
                key={index}
                data-aos="fade-up"
              >
                <DescriptionListIcon
                  icon={
                   <Icon fontIconClass={item.icon} className={classes.iconCover} />
                  }
                  title={item.title}
                  subtitle={item.description}
                  subtitleVariant="subtitle2"
                />
              </Grid>
              ))}
            <Grid item container xs={12} justify="center">
              <Button variant="contained" size="large" color="primary">
                {t('home.getStarted')}
              </Button>
            </Grid>
          </Grid>
        </CardBase>
      </SectionAlternate>   
    </div>
  );
};

Process.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Process;
