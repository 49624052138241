import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../../../firebase';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 550,
    margin: `0 auto`,
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const Form = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [formData, setFormData] = React.useState({});
  const [sent, setSent] = React.useState(false);
  const [sentError, setSentError] = React.useState(false);

  const updateInput = e => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      })
    }

    const handleSubmit = async(event) => {
      event.preventDefault() 
        try {
            const docRef = await addDoc(collection(db, "contactFormEmails"), {
              name: formData.name,
              email: formData.email,
              message: formData.message,
              time: new Date(),
            });
            console.log("Document written with ID: ", docRef.id);
            setSent(true);
            setSentError(false);
          } catch (e) {
            console.error("Error adding document: ", e);
            setSent(false);
            setSentError(true);
          }
      setFormData({
        name: '',
        email: '',
        message: '',
      })
    }

  return (
    <div className={className} {...rest}>
    {!sent && !sentError && <>
      <SectionHeader
        title={t('contact.cantFind')}
        data-aos="fade-up"
        align={isMd ? 'center' : 'left'}
      />
      <div className={classes.form}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              {t('contact.fullName')}
            </Typography>
            <TextField
              placeholder={t('contact.fullNamePlaceholder')}
              variant="outlined"
              size="medium"
              name="name"
              fullWidth
              type="text"
              onChange={updateInput}
              value={formData.name || ''}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              {t('contact.email')}
            </Typography>
            <TextField
              placeholder={t('contact.emailPlaceholder')}
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              type="email"
              onChange={updateInput}
              value={formData.email || ''}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              {t('contact.message')}
            </Typography>
            <TextField
              placeholder={t('contact.messagePlaceholder')}
              variant="outlined"
              name="message"
              fullWidth
              multiline
              rows={4}
              onChange={updateInput}
              value={formData.message || ''}
            />
          </Grid>
          <Grid item container justify="center" xs={12}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              onSubmit={handleSubmit}
              onClick={handleSubmit}
            >
              {t('contact.submit')}
            </Button>
          </Grid>
        </Grid>
      </div>
      </>
    }
    {sent && !sentError &&
      <SectionHeader
        title={t('contact.sentSuccess')}
        subtitle={t('contact.sentSuccessSub')}
        data-aos="fade-up"
        align={isMd ? 'center' : 'left'}
        fadeUp
      />
        }
        {!sent && sentError &&
        <SectionHeader
         title={t('contact.sentError')}
         subtitle={t('contact.sentErrorSub')}
        data-aos="fade-up"
        align={isMd ? 'center' : 'left'}
        fadeUp
      />
       
        }
    </div>
  );
};

Form.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Form;
