import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  colors,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.embarq.primary.dark,
    borderRadius: theme.spacing(2),
  },
  textWhite: {
    color: 'white',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    maxWidth: 400,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiInputAdornment-root i': {
      color: 'white !important',
    },
  },
  ctaText: {
    color: theme.palette.embarq.neutral.light,
    textDecoration: 'none',
    fontSize:'0.9rem'
  },
}));

const Newsletter = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = React.useState("");
  const [subscribed, setSubscribed] = React.useState(false);
  const [subscribedError, setSubscribedError] = React.useState(false);

  const handleSubmitNewsletter = async(e) => {
     e.preventDefault();    
        try {
            const docRef = await addDoc(collection(db, "subscribers"), {
              email: email,
              time: new Date(),    
            });
              setSubscribed(true);
              setSubscribedError(false);
          } catch (e) {
            setSubscribed(false);
            setSubscribedError(true);
          }
  }
    

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section>
        {!subscribed && !subscribedError && <>
          <SectionHeader
            title={
              <span className={classes.textWhite}>
                {t('newsletter.title')}
              </span>
            }
            subtitle={
              <span className={classes.textWhite}>
                   {t('newsletter.subtitle')}
              </span>
            }
            fadeUp
          />
          <div className={classes.inputContainer}>
            <FormControl
              fullWidth
              variant="outlined"
              data-aos="fade-up"
              className={classes.formControl}
            >
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                  <IconButton type="submit" onClick={handleSubmitNewsletter}>
                    <Icon
                      fontIconClass="fas fa-paper-plane"
                      fontIconColor={colors.white}
                    />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={t('newsletter.email')}
                onChange={(e)=>setEmail(e.target.value)}
              />
            </FormControl>
          </div>
        </>
        }
        {subscribed && !subscribedError &&
          <SectionHeader
            title={
              <span className={classes.textWhite}>
                {t('newsletter.subscribedSuccess')}
              </span>
            }
            subtitle={
              <span className={classes.textWhite}>
                   {t('newsletter.subscribedSuccessSub')}
              </span>
            }
            fadeUp
          />
        }
        {!subscribed && subscribedError &&
          <SectionHeader
            title={
              <span className={classes.textWhite}>
                {t('newsletter.subscribedError')}
              </span>
            }
            fadeUp
            ctaGroup={[
                <Typography
                  className={classes.ctaText}
                  component="a"
                  href="/contact"
                >
                 <b> {`${t('newsletter.subscribedErrorSub')}`}</b>
                 <Icon fontIconClass={'fas fa-arrow-right'} size="xxs" />
                </Typography>,
              ]}
          />
        }
      </Section>
    </div>
  );
};

Newsletter.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Newsletter;
