import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { HeroShaped, Map } from 'components/organisms';
import { Icon } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  mapContainer:{
     zIndex: 12,
     position: 'absolute',
     [theme.breakpoints.up('md')]: {
      content:"url('../../../images/illustrations/map.png')"
    },
     [theme.breakpoints.up('lg')]: {
      content:"url('../../../images/illustrations/map4.png')"
    },
   },
   imageContainer:{
    [theme.breakpoints.up('md')]: {
      background: "url('../../../images/photos/mtl_924x520.jpeg') no-repeat center center fixed",
      // content:"url('../../../images/photos/mtl_924x520.jpeg')"
    },
   },
  icon: {
    background: 'transparent',
    // borderRadius: 0,
    color:theme.palette.primary.main
  },
}));

const Contact = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const center = {lat: 45.502057, lng:-73.569345};

  return (
    <div className={className} {...rest}>
      <HeroShaped
        leftSide={
          <div>
            <SectionHeader
              title={t('contact.details')}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              data-aos="fade-up"
              align="left"
            />
            <List disablePadding>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    className={classes.icon}
                  >
                  <Icon 
                    fontIconClass="fas fa-envelope"
                    size="medium"
                  />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={t('contact.email')}
                  secondary="info@embarq.tech"
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
              <ListItem disableGutters data-aos="fade-up">
                <ListItemAvatar>
                  <Avatar
                    className={classes.icon}
                  >
                  <Icon 
                    fontIconClass="fas fa-map-marked-alt"
                    size="medium"
                  />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={t('contact.office')}
                  secondary="3  Place Ville Marie, Montreal, QC"
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textSecondary',
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
            </List>
          </div>
        }
        rightSide={
          <div className={classes.mapContainer}/>
        }
      />
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Contact;
