import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
  },
  textBlue:{
    color: theme.palette.embarq.primary.dark,
  },
  image: {
    width:'100%',
    minWidth: '400',
     [theme.breakpoints.up('md')]: {
      width: 500,
    },
    [theme.breakpoints.up('xl')]: {
      width: 800,
    },
  }
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
    const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
          container
          justify="space-between"
          spacing={isMd ? 4 : 2}
          direction={isMd ? 'row' : 'column-reverse'}
        >
  
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
            <SectionHeader
              title={
                <span className={classes.textWhite}>
                  {t('howItWorks.heroTitle')}
                </span>
                }
              subtitle={
                <span className={classes.textWhite}>
                  {t('howItWorks.heroSubtitle')}
                </span>
                }
              subtitleVariant="h5"
              align="left"
              titleVariant="h2"
            />
          </Grid>
                 <Grid
            item
            container
            justify="center"
            alignItems="flex-start"
            xs={12}
            md={6}
            data-aos={'fade-up'}
          >
            <Image
              src="/images/illustrations/wfh_8.svg"
              alt="Find a Job"
              className={classes.image}
            />
          </Grid>
        </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
