import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  colors,
} from '@material-ui/core';
import { Image, Icon } from 'components/atoms';
import { SectionHeader, IconAlternate } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  listItemAvatar: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  icon: {
    borderRadius: theme.spacing(0, 1),
  },
  textBlue:{
    color: theme.palette.embarq.primary.dark,
  },
  ctaText: {
    color: theme.palette.embarq.primary.dark,
    textDecoration: 'none',
    fontSize:'1rem'
  },
}));

const Features = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const data = t('home.advantages');

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6}>
          <SectionHeader
            title={
                <span className={classes.textBlue}>
                  {t('home.engineerTitle')}
                </span>
                }
              subtitle={
                <span style={{color:theme.palette.embarq.neutral.dark}}>
                  {t('home.engineerSubtitle')}
                </span>
                }
            align="left"
            titleVariant="h4"
            subtitleVariant="h5"
            disableGutter
            data-aos="fade-up"
          />
          <List disablePadding>
            {data.map((item, index) => (
              <ListItem disableGutters key={index} data-aos="fade-up">
                <ListItemAvatar className={classes.listItemAvatar}>
                  <IconAlternate
                    size="extraSmall"
                    fontIconClass="fas fa-check"
                    color={colors.indigo}
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Image
            src="https://assets.maccarianagency.com/the-front/illustrations/working-on-sofa.svg"
            alt="..."
            className={classes.coverImage}
          />
        </Grid>
      </Grid>
      <Typography   
        className={classes.ctaText}
        component="a"
        href="/how-it-works"
      >
       {`${t('home.engineerJoinCTA')} `}<b> {`${t('home.engineerCTA')}  `}</b>
       <Icon fontIconClass={'fas fa-arrow-right'} size="xxs" />
      </Typography>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Features;
